import { navigate, PageProps } from "gatsby"
import React from "react"
import Button from "../../../components/Button"
import ActeNecesare, {
  ActNecesar,
} from "../../../components/CereriNew/ActeNecesare"
import FormeErrorLine from "../../../components/CereriNew/FormErrorLine"
import NewFormFileInput from "../../../components/CereriNew/FormFileInput"
import NewTabs, { I_NewTabItem } from "../../../components/CereriNew/NewTabs"
import { casnicSvg, comercialSvg } from "../../../components/Dash/svgs"
import Footer from "../../../components/Footer"
import FormInput from "../../../components/FormInput"
import { useFileInput } from "../../../components/Hooks/useFileInput"
import useScrollRestorationToTop from "../../../components/Hooks/useScrollRestorationToTop"
import { InfoMsgType } from "../../../components/InfoMsg"
import SiteMenu from "../../../components/siteMenu"
import {
  ALFA_NUMERIC_ERROR,
  ALFA_NUMERIC_REGEX,
  AMOUNT_REGEX,
  CNP_ERROR,
  CNP_REGEX,
  EMAIL_ERROR,
  EMAIL_REGEX,
  PHONENUMBER_ERROR,
  PHONENUMBER_REGEX,
} from "../../../utils/regex"
import { getUserInfo } from "../../../utils/services/keepAuth"

import { Helmet } from "react-helmet"
import { calcFilesSize } from "../../../components/Cereri/MultiFileUploader"
import NewCheckBox from "../../../components/CereriNew/CheckBox"
import CheckBoxAgreeToTerms from "../../../components/CereriNew/CheckBoxAgreeToTerms"
import PopupLayout from "../../../components/Popups/PopupLayout"
import { postSchimbareAdministrativa } from "../../../utils/api/schimbareAdministrativa"

type DataCerereAvizAmplasament = {
  isCasnic: boolean
  denumire: string
  cuicnp: string
  adresa: string
  localitate: string
  judet: string
  numeReprezentant?: string
  email: string
  telefon: string
  locConsum: {
    isSame: boolean
    adresa: string
    localitate: string
    judet: string
    clc: string
    nrCadastral: string
    serieContor: string
    indexContor: string
  }
  acordReziliere: boolean
  acordPlata: boolean
  acordDate: boolean
}
const initialFormData: DataCerereAvizAmplasament = {
  isCasnic: true,
  denumire: "",
  cuicnp: "",
  adresa: "",
  localitate: "",
  judet: "",
  email: "",
  telefon: "",
  locConsum: {
    isSame: true,
    adresa: "",
    localitate: "",
    judet: "",
    clc: "",
    nrCadastral: "",
    serieContor: "",
    indexContor: "",
  },
  acordReziliere: false,
  acordPlata: false,
  acordDate: false,
}

const listaActeNecesare: ActNecesar[][] = [
  [
    { title: "Buletin (C.I) proprietar nou" },
    { title: "Act proprietate / folosință spațiu" },
    { title: "Acordul proprietarului" },
    { title: "Poză contor*" },
  ],
  [
    { title: "Certificat Inregistrare Fiscală" },
    { title: "Buletin (C.I) reprezentat legal" },
    { title: "Act proprietate / folosință spațiu" },
    { title: "Acordul proprietarului" },
    { title: "Poză contor*" },
  ],
]
const cereriButtonText = [
  "Începe completarea cererii",
  "Pasul următor",
  "Trimite cererea",
]
const tipClientTabItems: I_NewTabItem[] = [
  {
    id: 1,
    title: "Casnic",
    icon: casnicSvg,
    iconClassName: "casnic",
  },
  {
    id: 2,
    title: "Comercial",
    icon: comercialSvg,
    iconClassName: "comercial",
  },
]
const locConsumTabItems: I_NewTabItem[] = [
  {
    id: 1,
    title: "Da",
  },
  {
    id: 2,
    title: "Nu",
  },
]

function getFormErrorMessageForStep(step: number) {
  switch (step) {
    case 1:
      return ""
    case 2:
      return "Te rugăm să anexezi documentele necesare"
    case 3:
      return "Te rugăm să completezi toate câmpurile"
    case 4:
      return "Acceptă termenii și condițiile pentru a putea trimite cererea"
    default:
      return ""
  }
}

function isButtonEnabled(
  step: number,
  formData: DataCerereAvizAmplasament,
  files: File[]
) {
  if (step === 1) {
    return files.length > 0 && calcFilesSize(files) < 14.99
  }
  if (step === 2) {
    return (
      ALFA_NUMERIC_REGEX(formData.denumire) &&
      (formData.isCasnic
        ? CNP_REGEX(formData.cuicnp)
        : ALFA_NUMERIC_REGEX(formData.cuicnp)) &&
      ALFA_NUMERIC_REGEX(formData.adresa) &&
      ALFA_NUMERIC_REGEX(formData.localitate) &&
      ALFA_NUMERIC_REGEX(formData.judet) &&
      EMAIL_REGEX(formData.email) &&
      PHONENUMBER_REGEX(formData.telefon) &&
      (!formData.locConsum.isSame
        ? ALFA_NUMERIC_REGEX(formData.locConsum.adresa) &&
          ALFA_NUMERIC_REGEX(formData.locConsum.localitate) &&
          ALFA_NUMERIC_REGEX(formData.locConsum.judet)
        : true) &&
      ALFA_NUMERIC_REGEX(formData.locConsum.nrCadastral) &&
      ALFA_NUMERIC_REGEX(formData.locConsum.serieContor) &&
      ALFA_NUMERIC_REGEX(formData.locConsum.clc) &&
      AMOUNT_REGEX(formData.locConsum.indexContor) &&
      formData.acordDate &&
      formData.acordPlata &&
      formData.acordReziliere
    )
  }

  return false
}

export default function CerereAvizAmplasament({ location, params }: PageProps) {
  const [infoMsg, setInfoMsg] = React.useState<InfoMsgType>({
    isLoading: false,
    // status: "error",
  })
  const [step, setStep] = React.useState<number>(1)
  const [formData, setFormData] =
    React.useState<DataCerereAvizAmplasament>(initialFormData)
  const [nrHover, setNrHover] = React.useState(0)

  const timeOutRefs = React.useRef<NodeJS.Timeout[]>([])

  const fileInputRef = React.useRef<HTMLInputElement | null>(null)
  const { files, removeFile, resetFiles, changeFiles, uploadFiles } =
    useFileInput(fileInputRef)

  React.useEffect(() => {
    // Page unmount cleanup function
    return () => {
      timeOutRefs.current.forEach(clearTimeout)
    }
  }, [])

  React.useEffect(() => {
    const step = parseInt(params["step"] ?? "-1")
    if (!isNaN(step) && step > 0 && step < 5) {
      let maxStep = 0
      for (let i = maxStep; i < step; i++) {
        if (isButtonEnabled(i, formData, files)) {
          maxStep = i
        }
      }
      // console.log("Max step: ", maxStep, step)
      if (maxStep + 1 < step) {
        navigate(`../${maxStep + 1}`)
      } else setStep(Math.min(maxStep + 1, step))
      // navigate(`../${maxStep + 1}`)
    } else navigate("../1")
  }, [params])

  React.useEffect(() => {
    setNrHover(0)
  }, [step])

  useScrollRestorationToTop("body", location.pathname)

  const handleFormDataChange = (value: string, name: string) => {
    setFormData(oldFormData => ({
      ...oldFormData,
      [name]: value,
    }))
  }

  const handleSubmit = async () => {
    const root = document.getElementById("cerereSchimbareProprietarPage")
    if (root != null)
      root.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant",
      })
    setInfoMsg({
      isLoading: true,
    })

    // console.log("Handle form submit")
    try {
      const data = new FormData()

      for (var i in files) data.append("file", files[i])

      if (getUserInfo() != null) data.append("accountId", getUserInfo()!._id)

      data.append("isCasnic", formData.isCasnic + "")
      data.append("denumire", formData.denumire)
      data.append("cuicnp", formData.cuicnp)
      data.append("adresa", formData.adresa)
      data.append("localitate", formData.localitate)
      data.append("judet", formData.judet)
      data.append("email", formData.email)
      data.append("telefon", formData.telefon)
      if (!formData.locConsum.isSame) {
        data.append("lcadresa", formData.locConsum.adresa)
        data.append("lclocalitate", formData.locConsum.localitate)
        data.append("lcjudet", formData.locConsum.judet)
      }
      if (formData.numeReprezentant != null)
        data.append("numeReprezentantLegal", formData.numeReprezentant)

      data.append("lcclc", formData.locConsum.clc)
      data.append("lcnrCadastral", formData.locConsum.nrCadastral)
      data.append("lcserieContor", formData.locConsum.serieContor)
      data.append("lcindexContor", formData.locConsum.indexContor)

      data.append("acordDate", formData.acordDate + "")
      data.append("acordPlata", formData.acordPlata + "")
      // data.append("acordContactCompletari", formData.acordContactCompletari)
      data.append("acordReziliere", formData.acordReziliere + "")
      const apiResponse = await postSchimbareAdministrativa(data)
      if (apiResponse.type) {
        setInfoMsg({
          isLoading: true,
          status: "ok",
          msg: "Veți primi un email de confirmarea.  Te redirecționăm către pagina principală",
        })
        timeOutRefs.current.push(
          setTimeout(() => {
            navigate("/")
          }, 5000)
        )
        // setFormData(initialFormData)
      } else if (apiResponse.filesToLarge) {
        setInfoMsg({
          isLoading: true,
          status: "error",
          msg: "A apărut o eroare la transmiterea cererii. Fișierele încărcate depășesc limita de 20MB. Scoateți fișierele care au o dimensiunea mare. Vă vom solicita restul documentelor prin email. Te redirecționăm către pasul încarcare documente",
        })
        timeOutRefs.current.push(
          setTimeout(() => {
            setStep(2)
            setInfoMsg({ isLoading: false })
          }, 5000)
        )
      } else {
        setInfoMsg({
          isLoading: true,
          status: "error",
          msg: "Vă rugăm reîncercați. Acest pop-up va dispărea în câteva momente!",
        })
        timeOutRefs.current.push(
          setTimeout(() => {
            setInfoMsg({ isLoading: false })
          }, 3000)
        )
      }
    } catch (error) {
      console.warn(error)
      setInfoMsg({
        isLoading: true,
        status: "error",
        msg: "Vă rugăm reîncercați.Acest pop-up va dispărea în câteva momente!",
      })
      timeOutRefs.current.push(
        setTimeout(() => {
          setInfoMsg({ isLoading: false })
        }, 3000)
      )
    }
  }

  const handleLocConsumDataChange = (value: string, name: string) => {
    setFormData(oldData => ({
      ...oldData,
      locConsum: {
        ...oldData.locConsum,
        [name as keyof DataCerereAvizAmplasament["locConsum"]]: value,
      },
    }))
  }

  // console.log(formData)
  // console.log(files)

  console.log(infoMsg)

  return (
    <div id="cerereSchimbareProprietarPage" className="cererePage">
      <Helmet>
        <title>Cerere Schimbare Proprietar - Gazmir</title>
        <meta
          name="description"
          content="Depune o Cerere de Schimbare Proprietar pe Gazmir.ro. Transfera locul de consum pe numele tau. Beneficiaza de toate serviciile Gazmir in varianta online."
        />
        <meta
          name="keywords"
          content="Schimbare titular Gazmir,Schimbare titular gaz miroslava,Schimbare titular gaz panciu,Schimbare titular gaz odobesti,Schimbare titular gaz chirnogi,Cerere schimbare titular gazmir,Cerere schimbare proprietar gazmir,gaz pe numele meu,Schimbare titular contract gazmir,Schimbare titular contract gazmir miroslava,Schimbare titular contract gazmir panciu,Schimbare titular contract gazmir odobesti,Schimbare titular contract gazmir chirnogi,Cerere Schimbare titular Gazmir,Modificare titular contract furnizare,Modificate nume titular gazmir"
        />
      </Helmet>
      <SiteMenu location={location} showPaymentRedirectLoading={false} />

      <form id="formSchimbareProprietar" className="newCereriContainer">
        <h1 className="title">Cerere schimbare proprietar</h1>

        {step === 1 && (
          <div className="cerereStep1 cerereStep2">
            <NewTabs
              containerClassName="tipClientContainer"
              title="Tip client"
              subTitle="Ești client casnic sau comercial?"
              items={tipClientTabItems}
              idTabSelected={formData.isCasnic ? 1 : 2}
              setIdTabSelected={(id: number) =>
                setFormData(oldData => ({ ...oldData, isCasnic: id === 1 }))
              }
            />
            <div className="stepTitle marginTop24">Acte necesare</div>
            <div className="stepSubTitle">
              Te rugăm să te asiguri că toate datele sunt vizibile și lizibile
            </div>
            <ActeNecesare
              items={listaActeNecesare[formData.isCasnic ? 0 : 1]}
              withoutText
            />

            <NewFormFileInput
              files={files}
              changeFiles={changeFiles}
              removeFile={removeFile}
              fileInputRef={fileInputRef}
              id="fileInputavizAmplasament"
              buttonLabel="Anexează documente"
            />
            {/* <div className="stepSubTitle">
              Documentele trebuie să aibă maxim 15mb și să fie formatul
              .pdf/.jpeg/.png.
            </div> */}
          </div>
        )}
        {step === 2 && (
          <div className="cerereStep3">
            <div className="formDate formDateIdentificare ncsShadow">
              <div className="formSectionTitle">
                {formData.isCasnic
                  ? "Date personale titular (din C.I.)"
                  : "Date de indetificare societate"}
              </div>
              <FormInput
                type="text"
                key={formData.isCasnic ? "CD" : "PD"}
                containerClassName="ncsInputContainer"
                label={
                  formData.isCasnic ? "Nume și prenume" : "Denumire societate"
                }
                name="denumire"
                onChange={handleFormDataChange}
                value={formData.denumire}
                errorTest={ALFA_NUMERIC_REGEX}
                errorMessage={ALFA_NUMERIC_ERROR}
                showWithoutTouched={nrHover > 1}
              />
              <FormInput
                type="text"
                key={formData.isCasnic ? "CC" : "PC"}
                containerClassName="ncsInputContainer"
                label={
                  formData.isCasnic
                    ? "CNP - Cod Numeric Personal"
                    : "Certificat unic de înregistrare (C.U.I.)"
                }
                name="cuicnp"
                onChange={handleFormDataChange}
                value={formData.cuicnp}
                errorTest={formData.isCasnic ? CNP_REGEX : ALFA_NUMERIC_REGEX}
                errorMessage={
                  formData.isCasnic ? CNP_ERROR : ALFA_NUMERIC_ERROR
                }
                showWithoutTouched={nrHover > 1}
              />

              <FormInput
                type="text"
                key={formData.isCasnic ? "CA" : "PA"}
                containerClassName="ncsInputContainer"
                // containerStyle={cereriStyles.inputContainer}
                // style={cereriStyles.input}
                // errorStyle={cereriStyles.inputError}
                label={
                  formData.isCasnic ? "Adresă Domiciliu" : "Adresă sediu social"
                }
                name="adresa"
                onChange={handleFormDataChange}
                value={formData.adresa}
                errorTest={ALFA_NUMERIC_REGEX}
                errorMessage={ALFA_NUMERIC_ERROR}
                showWithoutTouched={nrHover > 1}
              />
              <FormInput
                type="text"
                key={formData.isCasnic ? "CL" : "PL"}
                containerClassName="ncsInputContainer"
                // containerStyle={cereriStyles.inputContainer}
                // style={cereriStyles.input}
                // errorStyle={cereriStyles.inputError}
                label="Localitate"
                name="localitate"
                onChange={handleFormDataChange}
                value={formData.localitate}
                errorTest={ALFA_NUMERIC_REGEX}
                errorMessage={ALFA_NUMERIC_ERROR}
                showWithoutTouched={nrHover > 1}
              />
              <FormInput
                type="text"
                key={formData.isCasnic ? "CJ" : "PJ"}
                containerClassName="ncsInputContainer"
                // containerStyle={cereriStyles.inputContainer}
                // style={cereriStyles.input}
                // errorStyle={cereriStyles.inputError}
                label="Județ"
                name="judet"
                onChange={handleFormDataChange}
                value={formData.judet}
                errorTest={ALFA_NUMERIC_REGEX}
                errorMessage={ALFA_NUMERIC_ERROR}
                showWithoutTouched={nrHover > 1}
              />
              {!formData.isCasnic && (
                <FormInput
                  type="text"
                  key="PNR"
                  containerClassName="ncsInputContainer"
                  // containerStyle={cereriStyles.inputContainer}
                  // style={cereriStyles.input}
                  // errorStyle={cereriStyles.inputError}
                  label="Numele reprezentantului legal"
                  name="numeReprezentant"
                  onChange={handleFormDataChange}
                  value={formData.numeReprezentant}
                  errorTest={ALFA_NUMERIC_REGEX}
                  errorMessage={ALFA_NUMERIC_ERROR}
                  showWithoutTouched={nrHover > 1}
                />
              )}
              <FormInput
                type="text"
                key={formData.isCasnic ? "CE" : "PE"}
                containerClassName="ncsInputContainer"
                // containerStyle={cereriStyles.inputContainer}
                // style={cereriStyles.input}
                // errorStyle={cereriStyles.inputError}
                label="Email"
                name="email"
                onChange={handleFormDataChange}
                value={formData.email}
                errorTest={EMAIL_REGEX}
                errorMessage={EMAIL_ERROR}
                showWithoutTouched={nrHover > 1}
              />
              <FormInput
                type="text"
                key={formData.isCasnic ? "CT" : "PT"}
                containerClassName="ncsInputContainer"
                // containerStyle={cereriStyles.inputContainer}
                // style={cereriStyles.input}
                // errorStyle={cereriStyles.inputError}
                label="Număr de telefon"
                name="telefon"
                onChange={handleFormDataChange}
                value={formData.telefon}
                errorTest={PHONENUMBER_REGEX}
                errorMessage={PHONENUMBER_ERROR}
                showWithoutTouched={nrHover > 1}
              />
            </div>
            <div className="formDate ncsShadow">
              <NewTabs
                containerClassName="tabInsideShadowBox"
                title="Loc consum"
                subTitle={`Locul de consum este același cu ${
                  formData.isCasnic ? "domiciliul" : "sediul social al firmei"
                }?`}
                items={locConsumTabItems}
                idTabSelected={formData.locConsum.isSame ? 1 : 2}
                setIdTabSelected={(id: number) =>
                  setFormData(oldData => ({
                    ...oldData,
                    locConsum: { ...oldData.locConsum, isSame: id === 1 },
                  }))
                }
              />
              {!formData.locConsum.isSame && (
                <div className="formDateInsideShadowBox">
                  <FormInput
                    type="text"
                    key="LCA"
                    containerClassName="ncsInputContainer"
                    label="Adresă loc consum"
                    name="adresa"
                    onChange={handleLocConsumDataChange}
                    value={formData.locConsum.adresa}
                    errorTest={ALFA_NUMERIC_REGEX}
                    errorMessage={ALFA_NUMERIC_ERROR}
                    showWithoutTouched={nrHover > 1}
                  />
                  <FormInput
                    type="text"
                    key="LCL"
                    containerClassName="ncsInputContainer"
                    // containerStyle={cereriStyles.inputContainer}
                    // style={cereriStyles.input}
                    // errorStyle={cereriStyles.inputError}
                    label="Localitate"
                    name="localitate"
                    onChange={handleLocConsumDataChange}
                    value={formData.locConsum.localitate}
                    errorTest={ALFA_NUMERIC_REGEX}
                    errorMessage={ALFA_NUMERIC_ERROR}
                    showWithoutTouched={nrHover > 1}
                  />
                  <FormInput
                    type="text"
                    key="LCJ"
                    containerClassName="ncsInputContainer"
                    // containerStyle={cereriStyles.inputContainer}
                    // style={cereriStyles.input}
                    // errorStyle={cereriStyles.inputError}
                    label="Județ"
                    name="judet"
                    onChange={handleLocConsumDataChange}
                    value={formData.locConsum.judet}
                    errorTest={ALFA_NUMERIC_REGEX}
                    errorMessage={ALFA_NUMERIC_ERROR}
                    showWithoutTouched={nrHover > 1}
                  />
                </div>
              )}
            </div>

            <div className="formDate ncsShadow">
              <div className="formSectionTitle">
                Datele locului de consum pentru care se efectuează schimbarea:
              </div>
              <FormInput
                type="text"
                key="lnc"
                containerClassName="ncsInputContainer"
                label="Număr Cadastral"
                name="nrCadastral"
                onChange={handleLocConsumDataChange}
                value={formData.locConsum.nrCadastral}
                errorTest={ALFA_NUMERIC_REGEX}
                errorMessage={ALFA_NUMERIC_ERROR}
                showWithoutTouched={nrHover > 1}
              />
              <FormInput
                type="text"
                key="lclc"
                containerClassName="ncsInputContainer"
                label="CLC- Cod loc consum"
                name="clc"
                onChange={handleLocConsumDataChange}
                value={formData.locConsum.clc}
                errorTest={ALFA_NUMERIC_REGEX}
                errorMessage={ALFA_NUMERIC_ERROR}
                showWithoutTouched={nrHover > 1}
              />
              <FormInput
                type="text"
                key="lserie"
                containerClassName="ncsInputContainer"
                label="Serie Contor"
                name="serieContor"
                onChange={handleLocConsumDataChange}
                value={formData.locConsum.serieContor}
                errorTest={ALFA_NUMERIC_REGEX}
                errorMessage={ALFA_NUMERIC_ERROR}
                showWithoutTouched={nrHover > 1}
              />
              <FormInput
                type="text"
                key="lindex"
                containerClassName="ncsInputContainer"
                label="Index Contor"
                name="indexContor"
                onChange={handleLocConsumDataChange}
                value={formData.locConsum.indexContor}
                errorTest={ALFA_NUMERIC_REGEX}
                errorMessage={ALFA_NUMERIC_ERROR}
                showWithoutTouched={nrHover > 1}
              />
            </div>
            <NewCheckBox
              id="acordReziliere"
              checked={formData.acordReziliere}
              labelText="Declar pe propria răspundere că fostul proprietar este de acord cu rezilierea contractului de furnizare"
              onChange={() => {
                setFormData(oldData => ({
                  ...oldData,
                  acordReziliere: !oldData.acordReziliere,
                }))
              }}
              showError={nrHover > 1 && !formData.acordReziliere}
            />
            <NewCheckBox
              id="acordPlata"
              checked={formData.acordPlata}
              labelText="Declar pe propria răspundere că voi achita soldul corespondent locului de consum vizat și taxa de analiză a cererii"
              onChange={() => {
                setFormData(oldData => ({
                  ...oldData,
                  acordPlata: !oldData.acordPlata,
                }))
              }}
              showError={nrHover > 1 && !formData.acordPlata}
            />
            <CheckBoxAgreeToTerms
              id="acordDate"
              checked={formData.acordDate}
              onChange={() =>
                setFormData(oldData => ({
                  ...oldData,
                  acordDate: !oldData.acordDate,
                }))
              }
              showError={nrHover > 1 && !formData.acordDate}
            />
          </div>
        )}

        <FormeErrorLine
          status="error"
          errorMessage={getFormErrorMessageForStep(step)}
          doesFormHaveErrors={!isButtonEnabled(step, formData, files)}
          nrHover={nrHover}
          setNrHover={setNrHover}
        />
        {infoMsg.status !== "ok" && (
          <Button
            isLoading={infoMsg.isLoading}
            as={step < 2 ? "Link" : "button"}
            to={step < 2 ? `../${step + 1}` : undefined}
            disabled={!isButtonEnabled(step, formData, files)}
            aspect="primary"
            className="ncsButton"
            label={cereriButtonText[step - 1]}
            onClick={step < 2 ? undefined : handleSubmit}
          />
        )}
      </form>
      <PopupLayout
        querySelectorForBlurredBackground="#formSchimbareProprietar"
        querySelectorForStopScrolling="#cerereSchimbareProprietarPage"
        titleExtraClassName="popupExtraTitle"
        popupState={{
          showPopup:
            step === 2 && (infoMsg.isLoading || infoMsg.status != null),
          type: "infoCerere",
          title:
            infoMsg.isLoading && infoMsg.status == null
              ? "Se trimite cererea"
              : infoMsg.status === "ok"
              ? "Cererea a fost trimisă cu succes"
              : "A apărut o eroare la transmiterea cererii",
          infoMsg: infoMsg,
        }}
      />
      <Footer />
    </div>
  )
}

const testFormData = {
  isCasnic: true,
  denumire: "Sârbu Alexandru",
  cuicnp: "1940527225898",
  nrInreg: "",
  adresa: "Str. Gându, nr.6",
  localitate: "Iași",
  judet: "Iași",
  email: "sarbu.alex27@gmail.com",
  telefon: "0753500775",
  locConsum: {
    isSame: true,
    // adresa: "Str. Clopotari, nr.28",
    // localitate: "Iași",
    // judet: "Iași",
  },
  reprezentantLegal: {
    isReprezentant: false,
    // nume: "Marcoci-Gînju Cosmin",
  },
  modalitateRaspuns: 1,
  agreedToTerms: false,
  nume: "g",
}

const testDataPJ = {
  isCasnic: false,
  denumire: "S.C. AZZA DDC S.R.L.",
  cuicnp: "34728271",
  nrInreg: "J22/1181/2015",
  adresa: "Str. Gandu, nr. 6",
  localitate: "Aron",
  judet: "Iasi",
  email: "sarbu.alex27@gmail.com",
  telefon: "0710100100",
  locConsum: {
    isSame: false,
    adresa: "Str. Clopotari",
    localitate: "Aroneanu",
    judet: "Iasi",
  },
  reprezentantLegal: {
    isReprezentant: false,
    nume: "",
  },
  modalitateRaspuns: 1,
  agreedToTerms: true,
  numeReprezentant: "Sarbu Alexandru",
}
